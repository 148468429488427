export const bannerLanguage = (localkey: string) => {
  let languageZone;
  switch (localkey) {
    case 'hk':
      languageZone = 'ZH_HK'
      break;
    case 'en':
      languageZone = 'EN_US'
      break;
    case 'zh':
      languageZone = 'ZH_CN'
      break;
    default:
      languageZone = 'ZH_HK'
      break;
  }
  return languageZone
}

export const projectLanguage = (localkey: string) => {
  let languageZone;
  switch (localkey) {
    case 'en':
      languageZone = 'EN'
      break;
    case 'zh':
    case 'hk':
    default:
      languageZone = 'HK'
      break;
  }
  return languageZone
}
