
export const infoParse = (info: string) => {
  let infoList = [];
  const newList = {};
  try {
    infoList = JSON.parse(info);
    infoList.forEach((item) => {
      if (item.name) {
        newList[item.name] = item;
      } else {
        newList[item.key] = item;
      }
    });
  } catch (error) {
    console.log('infoParse', error)
    infoList = [];
  }
  return { infoList, infoMap: newList }
};
