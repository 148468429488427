export const chainIconMap = {
  ALGORAND: require('@images/projects/chainIcon/Algorand.webp'),
  APTOS: require('@images/projects/chainIcon/Aptos.webp'),
  ARBITRUM: require('@images/projects/chainIcon/Arbitrum.webp'),
  ASTAR: require('@images/projects/chainIcon/Astar.webp'),
  AURORA: require('@images/projects/chainIcon/Aurora.webp'),
  AVALANCHE: require('@images/projects/chainIcon/Avalanche.webp'),
  AXLELAR: require('@images/projects/chainIcon/Axlelar.webp'),
  'BNB CHAIN': require('@images/projects/chainIcon/BNB Chain.webp'),
  BEACON: require('@images/projects/chainIcon/Beacon.webp'),
  CARDANO: require('@images/projects/chainIcon/Cardano.webp'),
  CRONOS: require('@images/projects/chainIcon/Cronos.webp'),
  DASH: require('@images/projects/chainIcon/Dash.webp'),
  EOS: require('@images/projects/chainIcon/EOS.webp'),
  ETH: require('@images/projects/chainIcon/ETH.webp'),
  ETHEREUM: require('@images/projects/chainIcon/Ethereum.webp'),
  FANTOM: require('@images/projects/chainIcon/Fantom.webp'),
  FLOW: require('@images/projects/chainIcon/Flow.webp'),
  HARMONY: require('@images/projects/chainIcon/Harmony.webp'),
  HEDERA: require('@images/projects/chainIcon/Hedera.webp'),
  HIVE: require('@images/projects/chainIcon/Hive.webp'),
  KARDIACHAIN: require('@images/projects/chainIcon/KardiaChain.webp'),
  KLAYTN: require('@images/projects/chainIcon/Klaytn.webp'),
  MOONBEAM: require('@images/projects/chainIcon/Moonbeam.webp'),
  MOONRIVER: require('@images/projects/chainIcon/Moonriver.webp'),
  NEAR: require('@images/projects/chainIcon/Near.webp'),
  OKBC: require('@images/projects/chainIcon/OKBC.webp'),
  OKTC: require('@images/projects/chainIcon/OKTC.webp'),
  'OASIS NETWORK': require('@images/projects/chainIcon/Oasis Network.webp'),
  ONTOLOGY: require('@images/projects/chainIcon/Ontology.webp'),
  OPTIMISM: require('@images/projects/chainIcon/Optimism.webp'),
  POLYGON: require('@images/projects/chainIcon/Polygon.webp'),
  RONIN: require('@images/projects/chainIcon/Ronin.webp'),
  SOLANA: require('@images/projects/chainIcon/Solana.webp'),
  STARSGAZE: require('@images/projects/chainIcon/Starsgaze.webp'),
  STEEM: require('@images/projects/chainIcon/Steem.webp'),
  SUI: require('@images/projects/chainIcon/Sui.webp'),
  TRON: require('@images/projects/chainIcon/TRON.webp'),
  TELOSEVM: require('@images/projects/chainIcon/TelosEVM.webp'),
  TEZOS: require('@images/projects/chainIcon/Tezos.webp'),
  THETA: require('@images/projects/chainIcon/Theta.webp'),
  THUNDERCORE: require('@images/projects/chainIcon/ThunderCore.webp'),
  VECHAIN: require('@images/projects/chainIcon/VeChain.webp'),
  VULCAN: require('@images/projects/chainIcon/Vulcan.webp'),
  WAX: require('@images/projects/chainIcon/WAX.webp'),
  CELO: require('@images/projects/chainIcon/celo.webp'),
  MOONBEAM_12100: require('@images/projects/chainIcon/moonbeam_12100.webp'),
  SHIDEN: require('@images/projects/chainIcon/shiden.webp'),
  TELOS: require('@images/projects/chainIcon/telos.webp'),
  ZKSYNC: require('@images/projects/chainIcon/zkSync.webp')
}
