
// @flow

/*
传递name，则返回name指定的属性，不传name全部返回
*/

export const imagesParse = (images: string, name?: string) => {
  let imagesObj = {};
  try {
    imagesObj = JSON.parse(images);
  } catch (error) {
    imagesObj = {};
  }
  if (name) return imagesObj[name];
  return imagesObj
};
